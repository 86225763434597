import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { IVideo } from '../../../interface/contents/video/IVideo';
import ReactPlayer from 'react-player/lazy';

import classnames from 'classnames/bind';
import styles from './ViewVideo.module.scss';
import useViewVideoData from './useViewVideoData';
import useViewVideoEventHandler from './useViewVideoEventHandler';
import useIsMounted from '../../../hook/useIsMounted';
import { Align, convertAlignToFlex } from '../../../interface/common/Align';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';

import { GroupIdType } from '../../../interface/group/IGroup';
import { OFF, ON } from '../../../constants/common';
import YoutubePlayButton from './YoutubePlayButton';

const cx = classnames.bind(styles);

interface IProps {
  wrapperRef: React.RefObject<HTMLDivElement>;
  refGroupId?: GroupIdType;
  viewVideo: IViewContents<IVideo>;
  viewMode?: ViewModeType;
  blockColumn?: number;
  onClick?: () => boolean;
}

const ViewVideo = ({ viewVideo, refGroupId, blockColumn, onClick, wrapperRef }: IProps) => {
  const { style, value } = viewVideo;
  const { alignType, autoPlayStatus } = style;
  const { thumbnailImage, thumbnailImageStatus } = value;

  const { playerRef, onReady, onPlay, onPause, onEnd, showThumbnailImage, handleClickPlayer, isLoop, isMuted } =
    useViewVideoEventHandler({
      viewVideo,
      onClick,
    });
  const { size, videoCalculatedStyle } = useViewVideoData({
    viewVideo,
    refGroupId,
    blockColumn,
    wrapperRef,
  });
  const { isMounted } = useIsMounted();

  if (!isMounted) return null;

  return (
    <div
      className={cx('content')}
      style={{
        justifyContent: convertAlignToFlex(Align[alignType]),
      }}
    >
      <div className={cx('youtube-wrapper')} style={videoCalculatedStyle} onClick={handleClickPlayer}>
        {thumbnailImage?.fileName && thumbnailImageStatus === ON && showThumbnailImage && autoPlayStatus === OFF && (
          <div className={cx('thumbnail-wrapper')}>
            <YoutubePlayButton />
            <img
              style={videoCalculatedStyle}
              className={cx('thumbnail-img')}
              src={getImageURL(thumbnailImage) ?? ''}
              alt={'thumbnail-image'}
            />
          </div>
        )}
        <ReactPlayer
          ref={playerRef}
          url={value.url}
          width={size.width}
          height={size.height}
          //@ts-ignore
          playIcon={<YoutubePlayButton />}
          controls={true}
          loop={isLoop}
          muted={isMuted}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnd}
          playsInline
        />
      </div>
    </div>
  );
};

export default ViewVideo;
