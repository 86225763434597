'use client';

import classNames from 'classnames/bind';
import { FC, useContext, useEffect, useState } from 'react';
import Pagination from 'ui/common/pagination/Pagination';
import RecruitList from 'ui/component/recruiter/recruitList/RecruitList';
import { IRecruit } from 'ui/interface/recruit/IRecruitList';
import PaginationVO from 'ui/model/vo/PaginationVO';
import { DEFAULT_PAGE } from '../../../../constants/common';
import RecruitListInjectedContext from '../../../../context/recruiter/RecruitListInjectedContext';
import RecruitSettingContext from '../../../../context/recruiter/RecruitSettingContext';
import { HasOption } from '../../../../interface/common/hasOption';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import IViewContents from '../../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../../interface/contents/recruitment/IRecruitment';
import { IPostListV1Response } from '../../../../interface/position/getList';
import style from './RecruitViewList.module.scss';

const cx = classNames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const RecruitViewList: FC<IProps & HasOption> = ({ viewMode, viewRecruitment, isPreview, isEdit, options }) => {
  const { tabValue, keyword, careerType, workingArea, jobGroup, submissionStatus, openStatus } =
    useContext(RecruitSettingContext);
  const {
    postList: _postList,
    mutatePostListData: _mutatePostListData,
    postListPreview: _postListPreview,
    mutatePostListPreviewData: _mutatePostListPreviewData,
  } = useContext(RecruitListInjectedContext);

  const postListData = isPreview || isEdit ? _mutatePostListPreviewData : _mutatePostListData;
  const { size } = viewRecruitment.value;

  const [list, setList] = useState<IRecruit[]>([]);

  const postListV1 = (page: number) => {
    const __postList = isPreview || isEdit ? _postListPreview : _postList;
    if (!__postList) return;

    // 여기서 확인
    __postList(
      {
        pageableRq: {
          page,
          size,
        },
        filter: {
          keyword,
          tagSnList: tabValue === '' ? [] : [tabValue],
          jobGroupSnList: jobGroup,
          careerTypeList: careerType,
          regionSnList: workingArea,
          submissionStatusList: submissionStatus,
          // openStatusList: openStatus,
          openStatusList: isPreview ? ['OPEN'] : [],
        },
      },
      {
        onSuccess: (data: IPostListV1Response) => {
          setList(data.list);
        },
      }
    );
  };

  useEffect(() => {
    postListV1(DEFAULT_PAGE);
  }, [
    _postList,
    _postListPreview,
    tabValue,
    keyword,
    careerType,
    workingArea,
    jobGroup,
    submissionStatus,
    openStatus,
    isPreview,
  ]);

  return (
    <>
      <RecruitList viewMode={viewMode} recruitList={list} JDUrl={'jobs'} readOnly={isPreview} options={options} />
      {list.length > 0 && (
        <div className={cx('pagination')}>
          <Pagination
            showOnlyPagination
            page={
              new PaginationVO({
                pageSize: postListData.pagination.size,
                ...postListData.pagination,
              })
            }
            pageHandler={(page) => {
              postListV1(page.page);
            }}
          />
        </div>
      )}
    </>
  );
};

export default RecruitViewList;
