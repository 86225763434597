import { useMemo } from 'react';
import { useMutationPostThumbnailPostList } from '@queries/post';
import { IViewBoardProps } from 'ui/component/contents/board/useViewBoard';

const useBoardContext = () => {
  const { mutate: postThumbnailPostList, data: mutatePostThumbnailPostListData } = useMutationPostThumbnailPostList();

  // @ts-ignore
  const viewBoardProps: IViewBoardProps = useMemo(() => {
    return { postThumbnailPostList, mutatePostThumbnailPostListData };
  }, [postThumbnailPostList, mutatePostThumbnailPostListData]);

  return {
    viewBoardProps,
  };
};

export default useBoardContext;
