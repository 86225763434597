import classNames from 'classnames/bind';
import Link from 'next/link';
import { FC, ReactNode } from 'react';
import Icon from '../../../common/icon/Icon';
import Tag, { TAG_COLOR } from '../../../common/tag/Tag';
import { COLORS } from '../../../constants/colors';
import { Language, LANGUAGE } from '../../../constants/language';
import {
  CAREER_DEFINITION,
  RECRUITMENT_TYPE_DEFINITION,
  SUBMISSION_STATUS_DEFINITION,
} from '../../../constants/position/common';
import { SOLUTION } from '../../../constants/solution/solution.constant';
import useWarningToast from '../../../context/toast/useWarningToast';
import { useUrl } from '../../../hook/useUrl';
import { HasOption } from '../../../interface/common/hasOption';
import { ViewModeType } from '../../../interface/common/ViewMode';
import { IRecruit } from '../../../interface/recruit/IRecruitList';
import { TSubmissionStatusType } from '../../../interface/recruit/IRecruitSetting';
import { formatDate, formatDateForKorea } from '../../../util/dayUtil';
import { SOLUTION_TYPE } from '../../../util/solution';
import NoResult from '../../common/NoResult';
import style from './RecruitList.module.scss';

const cx = classNames.bind(style);

interface IFilteredItem {
  children: React.ReactNode;
  replaceNull?: string;
}

const FilteredItem = ({ children, replaceNull }: IFilteredItem) => {
  if (!children && !replaceNull) return null;
  return (
    <div className={cx('filtered-item')}>
      <p>{children ?? replaceNull}</p>
    </div>
  );
};

interface IProps {
  viewMode?: ViewModeType;
  recruitList: IRecruit[];
  JDUrl?: string;
  readOnly?: boolean;
}

const SUBMISSION_STATUS_COLOR: Record<TSubmissionStatusType, keyof typeof TAG_COLOR> = {
  PRE_SUBMISSION: 'DARK',
  IN_SUBMISSION: 'TEAL',
  POST_SUBMISSION: 'GRAY',
};

const RecruitList: FC<IProps & HasOption> = ({
  viewMode = 'PC',
  recruitList = [],
  JDUrl = '',
  readOnly = false,
  options,
}) => {
  const { setMobilePreviewDisabledWarningToast } = useWarningToast();
  const { buildUrl } = useUrl();
  const languageType = (options && options['languageType']) ?? 'KOR';

  // 검색에 충족하는 공고가 없음
  if (recruitList.length === 0) {
    return <NoResult title={'진행 중인 공고가 없어요.'} />;
  }

  const ListItemWrapper = ({ children, positionSn }: { children: ReactNode; positionSn: number }) => {
    const getHref = () => {
      if (readOnly) {
        return buildUrl(`/recruit/${positionSn}`)
          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
          .getResult();
      } else {
        return buildUrl(`/${SOLUTION_TYPE === SOLUTION.JOBFLEX ? `career/${JDUrl}` : JDUrl}/${positionSn}`)
          .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
          .getResult();
      }
    };

    if (JDUrl) {
      return (
        <Link
          href={getHref()}
          className={cx('list-item', viewMode)}
          onClick={(e) => {
            if (readOnly && viewMode === 'MOBILE') {
              e.preventDefault();
              setMobilePreviewDisabledWarningToast();
            }
          }}
          target={'_blank'}
        >
          {children}
        </Link>
      );
    }
    return <div className={cx('list-item', viewMode)}>{children}</div>;
  };

  return (
    <div className={cx('recruit-list', viewMode)}>
      <ul>
        {recruitList.map((data) => {
          const { positionSn } = data;
          const { listItemInner } = createSubComponent(data, languageType);
          return (
            <li key={positionSn}>
              <ListItemWrapper positionSn={positionSn}>{listItemInner[viewMode || 'PC']}</ListItemWrapper>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecruitList;

// 내부 구성 요소를 만드는 함수 분리
const createSubComponent = (data: IRecruit, languageType: Language) => {
  const {
    dday,
    startDateTime,
    endDateTime,
    announcementType,
    fixStatus,
    jobGroupName,
    jobGroupEnglishName,
    workingAreaName,
    workingAreaEnglishName,
    careerType,
    title,
    classificationCode,
    recruitmentType,
  } = data;

  let ddayText = '';
  let submissionStatus: TSubmissionStatusType = 'PRE_SUBMISSION';

  const now = new Date();
  if (
    (SOLUTION_TYPE === SOLUTION.JOBDA && announcementType === 'ALWAYS') ||
    (SOLUTION_TYPE === SOLUTION.JOBFLEX && recruitmentType === 'PERMANENT')
  ) {
    ddayText = languageType === LANGUAGE.KOR ? '상시채용' : 'Ongoing';
    submissionStatus = 'IN_SUBMISSION';
  } else if (new Date(endDateTime) < now) {
    ddayText = '';
    submissionStatus = 'POST_SUBMISSION';
  } else if (dday === 0) {
    ddayText = 'D-Day';
    submissionStatus = 'IN_SUBMISSION';
  } else if (dday !== null) {
    ddayText = `D-${dday}`;
    submissionStatus = new Date(startDateTime) < now ? 'IN_SUBMISSION' : 'PRE_SUBMISSION';
  }

  const filteredItems = (replaceNull?: string) => {
    return {
      [SOLUTION.JOBDA]: (
        <>
          <FilteredItem replaceNull={replaceNull}>
            {languageType === LANGUAGE.KOR ? workingAreaName : workingAreaEnglishName || workingAreaName}
          </FilteredItem>
          <FilteredItem replaceNull={replaceNull}>
            {languageType === LANGUAGE.KOR ? jobGroupName : jobGroupEnglishName || jobGroupName}
          </FilteredItem>
          <FilteredItem replaceNull={replaceNull}>
            {careerType && CAREER_DEFINITION[careerType][languageType]}
          </FilteredItem>
        </>
      ),
      [SOLUTION.JOBFLEX]: (
        <>
          {languageType === LANGUAGE.KOR && <FilteredItem replaceNull={replaceNull}>{classificationCode}</FilteredItem>}
          <FilteredItem replaceNull={replaceNull}>
            {recruitmentType && RECRUITMENT_TYPE_DEFINITION[recruitmentType][languageType]}
          </FilteredItem>
          <FilteredItem replaceNull={replaceNull}>
            {careerType && CAREER_DEFINITION[careerType][languageType]}
          </FilteredItem>
        </>
      ),
    };
  };

  const subComponent: Record<string, ReactNode> = {
    SUBMISSION_BLOCK: (
      <Tag
        className={cx('submission-status-tag')}
        variant={submissionStatus === 'IN_SUBMISSION' ? 'primary' : 'secondary'}
        color={TAG_COLOR[SUBMISSION_STATUS_COLOR[submissionStatus]]}
        size={'lg'}
        round={false}
      >
        {SUBMISSION_STATUS_DEFINITION[submissionStatus][languageType]}
      </Tag>
    ),
    FIXED_PIN: (
      <>
        {fixStatus == 'FIXED' ? (
          <div className={cx('fixed_pin')}>
            <Icon name={'stick_filled'} color={COLORS.gray500} size={24} />
          </div>
        ) : null}
      </>
    ),
    FILTERED_LIST_PC: <div className={cx('filtered-list')}>{filteredItems('-')[SOLUTION_TYPE]}</div>,
    FILTERED_LIST: <div className={cx('filtered-list')}>{filteredItems()[SOLUTION_TYPE]}</div>,
    DATE_BLOCK: (
      <div className={cx('condition-date')}>
        {ddayText && <p className={cx('dday')}>{ddayText}</p>}
        <div className={cx('date')}>
          <p>{`${formatDateForKorea(startDateTime, false)} ~ `}</p>
          <p>{formatDate(endDateTime, true, '영입 종료 시')}</p>
        </div>
      </div>
    ),
  };

  const listItemInner: Record<ViewModeType, React.ReactNode> = {
    PC: (
      <>
        <div className={cx('left')}>
          <div className={cx('title-wrapper')}>
            {subComponent.SUBMISSION_BLOCK}
            <p className={cx('title')}>{title}</p>
            {subComponent.FIXED_PIN}
          </div>
          {subComponent.DATE_BLOCK}
        </div>
        {subComponent.FILTERED_LIST_PC}
      </>
    ),
    TABLET: (
      <>
        <div className={cx('title-wrapper')}>
          {subComponent.SUBMISSION_BLOCK}
          <p className={cx('title')}>{title}</p>
          {subComponent.FIXED_PIN}
        </div>
        {subComponent.FILTERED_LIST}
        {subComponent.DATE_BLOCK}
      </>
    ),
    MOBILE: (
      <>
        <div className={cx('top')}>
          {subComponent.SUBMISSION_BLOCK}
          {subComponent.FIXED_PIN}
        </div>
        <p className={cx('title')}>{title}</p>
        {subComponent.FILTERED_LIST}
        {subComponent.DATE_BLOCK}
      </>
    ),
  };

  return { listItemInner };
};
